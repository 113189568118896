import React, { Component } from "react";

import Step1 from "./submitjob/step1";
import Step2 from "./submitjob/step2";
import Step3 from "./submitjob/step3";
import Step4 from "./submitjob/step4";
import Step5 from "./submitjob/step5";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../api";
import { baseURL } from "../../constant";
import { withRouter } from "../common/withRouter";
import ProgresQuote from "./submitjob/progressbar";
  const cookies = new Cookies();

class GetaQuote extends Component {
  constructor(props) {
    super(props);
    

    const cookies = new Cookies();
    const checkLogin = cookies.get("islogin");
   
   
    cookies.set("sendOffers", '', { path: "/" });
    cookies.set("subscribed", '', { path: "/" });
    cookies.set("rcountry", 13, { path: "/" });
		cookies.set("country", 13, { path: "/" });
    cookies.set("taxvalue", '0', { path: "/" });
    cookies.set("currency", '1', { path: "/" });
    // Set the intiial input values
    this.state = {
      currencyText: 'AUD',
      taxAmount: '0',
      currentStep: 1,
      email: cookies.get("userEmail"),
      password: "",
      name: cookies.get("userName"),
      file: cookies.get("filecookies"),
      rcountry: cookies.get("rcountry"),
      country: cookies.get("country"),
      currency: cookies.get("currency"),
      rcurrency: cookies.get("rcurrency"),
      wordlength: cookies.get("wordlength"),
      serviceType: cookies.get("serviceType"),
      jobType: "Basic Service",
      price: cookies.get("price"),
      deliverydate: cookies.get("deliverydate"),
      voucher: "",
      rememberMe: "",
      message: "",
      messageSuccess: "",
      voucherMessage: "",
      formtab: "",
      success: "false",
     // isLogin: "false",
      isLogin: cookies.get('isLogin'),
      loginSuccess: this.loginSuccess,
      loginFail: this.loginFail,
      ratio: cookies.get("ratio"),
      editorFees: cookies.get("editorFees"),
      userId: cookies.get("userId"),
      jobId: cookies.get("jobId"),
      fullAmountQuoted:
        parseInt(cookies.get("price")) +
        parseInt(cookies.get("setting").regular_delivery_amount) +
        parseInt(cookies.get("setting").tax_amount),
      isFileUploaded: "",
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    // Bind the submission to uploadedFile()
    this.uploadedFile = this.uploadedFile.bind(this);
    // Bind the submission to Login()
    this.loginSubmit = this.loginSubmit.bind(this);
    // Bind the submission to JobType()
    this.getJobType = this.getJobType.bind(this);
    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    
  }


    loadJS(FILE_URL, async = true) {
      
    let scriptEle = document.createElement("script");

    scriptEle.setAttribute("src", FILE_URL);
    scriptEle.setAttribute("type", "text/javascript");
    scriptEle.setAttribute("async", async);

    document.body.appendChild(scriptEle);

    // success event 
    scriptEle.addEventListener("load", () => {
      console.log("File loaded")
      
    });
    // error event
    scriptEle.addEventListener("error", (ev) => {
      console.log("Error on loading file", ev);
    });
  }
  
  handleApplyVoucher = async () => {
   
    if (!this.voucher) {
        //setMessage("Please enter a voucher code.");
        this.setState({
          voucherMessage: "Please enter a voucher code",
        });
        return;
    }
    try {
        const response = await fetch("/api/voucher", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ code: this.voucher }),
        });
        const data = await response.json();
        if (data.success) {
           // setMessage("Voucher applied successfully!");
            this.setState({
              voucherMessage: "Voucher applied successfully!",
            });
        } else {
          //  setMessage("Invalid voucher code.");
            this.setState({
              voucherMessage: "Invalid voucher code.",
            });
        }
    } catch (error) {
      this.setState({
        voucherMessage: "An error occurred. Please try again.",
      });
     //   setMessage("An error occurred. Please try again.");
    }
};

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    const cookies = new Cookies();
    console.log(name, value, 73);
    const curr = "AUD";
    if(name === "rememberMe"){
      
      if(cookies.get('rememberMe') == 'on'){
        cookies.set("rememberMe", '', { path: "/" });
        this.setState({
          rememberMe: "",
        });
      }else{
        this.setState({
          rememberMe: "on",
        });
        cookies.set("rememberMe", 'on', { path: "/" });
      }
      console.log("nnnnn"+this.state.rememberMe)
    }
	if(name === "name"){
		cookies.set("userName", value, { path: "/" });
	}
  if(name === "subscribed"){
    if(cookies.get('subscribed') == 'on'){
      cookies.set("subscribed", '', { path: "/" });
    }else{
      cookies.set("subscribed", 'on', { path: "/" });
    }
		
	}
  if(name === "sendOffers"){
    if(cookies.get('sendOffers') == 'on'){
      cookies.set("sendOffers", '', { path: "/" });
    }else{
      cookies.set("sendOffers", 'on', { path: "/" });
    }
	}
	if(name === "email"){
		cookies.set("userEmail", value, { path: "/" });
	}
	if(name === "followUpDescription"){
		cookies.set("followUpDescription", value, { path: "/" });
	}
	if(name === "voucher"){
		cookies.set("voucher", value, { path: "/" });
	}
  if(name === "country"){
		cookies.set("country", value, { path: "/" });
	}
  if(name === "rcountry"){
		cookies.set("rcountry", value, { path: "/" });
		cookies.set("country", value, { path: "/" });
	}
  if(name === "language"){
		cookies.set("language", value, { path: "/" });
	}
  if(name === "rcurrency"){
		cookies.set("rcurrency", value, { path: "/" });
		cookies.set("currency", value, { path: "/" });
  
	}
    if(name === "currency"){
      const amount  = cookies.get('firstQuoteprice');
     
      if(value ==1){
        this.loadAmountAfterExchange(amount,'USD','AUD');
        const curr = "AUD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "AUD",
        });
      }else if(value ==2){
        console.log("curr---------")
        this.loadAmountAfterExchange(amount,'USD','GBP');
        const curr = "GBP";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "GBP",
        });
      }else if(value ==3){
        this.loadAmountAfterExchange(amount,'USD','USD');
        const curr = "USD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "USD",
        });
      }else if(value ==4){
        this.loadAmountAfterExchange(amount,'USD','HKD');
        const curr = "HKD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "HKD",
        });
      }else if(value ==5){
        this.loadAmountAfterExchange(amount,'USD','EUR');
        const curr = "EUR";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "EUR",
        });
      }else if(value ==6){
        this.loadAmountAfterExchange(amount,'USD','CAD');
        const curr = "CAD";
        var scriptsrc = 'https://www.paypal.com/sdk/js?client-id=ASf-1FjPx45SxHr0QI4iiRYjNijJGMr18MPo3uEvr4y6GqE64_mu1n7KyS0SeJb9lMc5TSkIUq7E78k5&currency='+curr;
        this.setState({
          currencyText: "CAD",
        });
      }

     
      this.loadJS(""+scriptsrc+"", true);


       cookies.set("currency", value, { path: "/" });
       if(value != "1"){
        cookies.set("taxvalue", '0', { path: "/" });
        this.setState({
          taxAmount: "0",
          changecurrency: value,
        });
       
      }else{
        //settaxAmount('0.00');
        cookies.set("taxvalue", '10', { path: "/" });
        this.setState({
          taxAmount: "10",
          changecurrency: value,
        });
      }
    }
    this.setState({
      [name]: value,
    });
  }
   loadAmountAfterExchange = async (amount, cfrom, cto) => {
    const items = [{ amount: cookies.get("firstQuoteprice") }];
    try {
            const response = await axiosRequest.get(`${baseURL}/loadAmountAfterExchange/`+amount+'/'+cfrom+'/'+cto, {
                items,
            }); 
            cookies.set("price", response.data.amount, { path: "/" });
         
            
        } catch (error) {
            console.log(error);
        }
    //}
  
    
    };
  // Trigger an alert on form submission
  handleSubmit = (event) => {
    event.preventDefault();
    const cookies = new Cookies();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const data = this.state;

    axiosRequest
      .post(`${baseURL}/submitQuotePageJob`, data, config)
      .then((response) => {
        if (response.data.success == true) {
          console.log(response.data.userId);
          if(response.data.voucherCode){
            cookies.set("userId", response.data.userId, { path: "/" });
            cookies.set("jobId", response.data.jobId, { path: "/" });

            cookies.set("islogin", "true", { path: "/" });
            cookies.set("userName", this.state.name, { path: "/" });
            cookies.set("checkLogin", "true", { path: "/" });

            cookies.set("userEmail", this.state.email, { path: "/" });
            cookies.set("voucherAppliedCode", response.data.voucherCode, { path: "/" });
            cookies.set("voucherAppliedDiscount", response.data.discount, { path: "/" });
            if(response.data.discountType == 1){
              cookies.set("discountType", '%', { path: "/" });
            }else{
              cookies.set("discountType", '', { path: "/" });
            }
            cookies.set("voucherAppliedDiscountType", response.data.discountType, { path: "/" });
            
            cookies.set("voucherApplied", true, { path: "/" });

            this.setState({ message: response.data.voucherMsg });
           // setMessage(response.data.voucherCode);
              console.log('Voucher Applied');
          }else{
            cookies.set("voucherAppliedCode", '', { path: "/" });
            cookies.set("voucherAppliedDiscount", '', { path: "/" });
            cookies.set("voucherAppliedDiscountType", '', { path: "/" });
          this.setState({ jobId: response.data.jobId });
          cookies.set("jobId", response.data.jobId, { path: "/" });
         
          this.setState({ userId: response.data.userId });
          if (response.data.userId) {
            cookies.set("userId", response.data.userId, { path: "/" });
            cookies.set("islogin", "true", { path: "/" });
            cookies.set("userName", this.state.name, { path: "/" });
            cookies.set("checkLogin", "true", { path: "/" });

            cookies.set("userEmail", this.state.email, { path: "/" });
          }
          if (this.state.currentStep === 5) {
            this.props.navigate("/thank-you");
          }
        }
        } else {
          console.log(response.data.message);
          this.setState({ message: response.data.message });
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loginSubmit = (event) => {
    event.preventDefault();
    const cookies = new Cookies();
    const data = this.state;

    axiosRequest
      .post(`${baseURL}/login`, data)
      .then((response) => {
        if (response.data.success === true) {
          let expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate()+1);
          if(this.state.rememberMe){
            // Create a Date object for 2 days from now
            expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 2);
          }else{
           
          }
          


          localStorage.setItem("isLoggedIn", "true");
          this.props.onLoginSuccess(response.data.name)
          console.log(this.state.rememberMe)
          if(this.state.rememberMe){
            cookies.set("islogin", "true", { path: "/" ,expires:expirationDate});
            cookies.set("userName", response.data.name, { path: "/" ,expires:expirationDate});
            cookies.set("userEmail", response.data.email, { path: "/",expires:expirationDate });
            cookies.set("userId", response.data.userId, { path: "/",expires:expirationDate });
          }else{
            cookies.set("islogin", "true", { path: "/"});
            cookies.set("userName", response.data.name, { path: "/"});
            cookies.set("userEmail", response.data.email, { path: "/" });
            cookies.set("userId", response.data.userId, { path: "/" });
          }


          cookies.set("membershipdiscount", response.data.membershipdiscount, { path: "/" });
          cookies.set("membershiptitle", response.data.membershiptitle, { path: "/" });
         
          this.setState({ loginSuccess: "true" });
          this.setState({ userId: response.data.userId });
          this.setState({ formtab: "Login" });
          this.setState({ name: response.data.name });
          //window.location.reload();

        } else {
          this.setState({ loginFail: "true" });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4 ? 5 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
  }

  uploadedFile(file) {
    this.setState({ files: file });
    this.setState({ isFileUploaded: "true" });
  }

  handleRemoveFile(file) {
    this.setState({ files: file });
  }

  getJobType(name) {
    const cookies = new Cookies();
    this.setState({ jobType: name });
    cookies.set("jobType", name, { path: "/" });
  }

  getTotalVal(val) {
    this.setState({ fullAmountQuoted: val });
  }

  // The "next" and "previous" button functions
  get previousButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <button className="btn btn-outline-primary ml-3" onClick={this._prev}>
          Back
        </button>
      );
    }

    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 5) {
      return (
        <button
          className={(this.state.currentStep === 1 && !this.state.isFileUploaded) || ((!cookies.get("islogin")) && this.state.currentStep === 2 && (!cookies.get("userName") || !cookies.get("userEmail") || cookies.get("subscribed") ==0)) ? 'hidden' : 'visible btn btn-primary mr-3'}
          onClick={this._next}
          disabled={(this.state.currentStep === 1 && !this.state.isFileUploaded) || ((!cookies.get("islogin")) && this.state.currentStep === 2 && (!cookies.get("userName") || !cookies.get("userEmail") || cookies.get("subscribed") ==0))}
        >
          Continue
        </button>
      );
    }
    // ...else render nothing
    return null;
  }

  get submitButton() {
    let currentStep = this.state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 4) {
      return <button className="btn btn-primary mr-3">Submit</button>;
    }
    // ...else render nothing
    return null;
  }

  componentDidUpdate(previousProps, previousState) {
    const cookies = new Cookies();
    let totalCalculatedAmount = cookies.get("fullAmount");
    if (
      previousState.fullAmountQuoted !== undefined &&
      previousState.fullAmountQuoted !== totalCalculatedAmount
    ) {
      this.getTotalVal(totalCalculatedAmount);
    }
    return;
  }

  render() {
    return (
      <>
        <section className="inner-page section-themebg">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h3>Get a Quote</h3>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding bg-gray">
          <div className="container">
            <div className="row">
              <div className="col-12">
      
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12 mb-5">
                      <div className="progress-box mb-5" data={this.state.currentStep}>
                        <ProgresQuote currentStep={this.state.currentStep} />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="custom-card-form cutsom-padding">
                      <form onSubmit={this.handleSubmit}>
                        <Step1
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                          getUploadedFile={this.uploadedFile}
                          gethandleRemoveFile={this.handleRemoveFile}
                        />
                        
                        <Step2
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                          loginSubmit={this.loginSubmit}
                          isLogin={this.state.isLogin}
                          loginSuccess={this.state.loginSuccess}
                          loginFail={this.state.loginFail}
                        />
                        
                        
                        <Step3
                          changecurrency={this.state.changecurrency}
                          messageSuccess={this.state.messageSuccess}
                          message={this.state.message}
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                        />
                         
                         
                        <Step4
                          currentStep={this.state.currentStep}
                          handleChange={this.handleChange}
                          selectedJobType={this.getJobType}
                        />
                         
                       

                        <div className="card-footer-button">
                          <div className="row">
                            <div className="col-6 text-left">
                              {this.previousButton}
                            </div>
                            <div className="col-6 text-right">
                              {this.nextButton}
							  <div className="submit_btn_class">
                              {this.submitButton}
							  </div>
                            </div>
                          </div>
                        </div>
                        </form> 
                        <Step5
                          taxAmount={this.state.taxAmount}
                          currentStep={this.state.currentStep}
                          currencyText={this.state.currencyText}
                          handleChange={this.handleChange}
                        />
                        {/* {this.state.success === "true" && (
                          <div className="d-flex align-items-center justify-content-end mt-3">
                            <span>You are Successfully submitted</span>
                            <Link to="/">
                              <button className="btn btn-primary mx-2">
                                Go to Home
                              </button>
                            </Link>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
           
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withRouter(GetaQuote);
